import React from "react";
import {Box, Grid, useMediaQuery} from "@material-ui/core";
import {FrontGridType} from "../../../types";
import {FrontContainerBox} from "./components/FrontContainerBox";
import {FrontBoxItem} from "./components/FrontBoxItem";
import {onClickPost} from "../../../utils";
import {useTheme} from "@material-ui/core/styles";
import { FrontBoxItemTwo } from './components/FrontBoxItemTwo';

export const FrontGridTwelve: React.FC<FrontGridType> = ({
  items, sectionId, random = false
}) => {
  let item1 = items.find((item)=>{return item.position === 1;});
  let item2 = items.find((item)=>{return item.position === 2;});
  let item3 = items.find((item)=>{return item.position === 3;});

  return (
    <Grid container  spacing={1} >
      <Grid item md={6}>
        <FrontContainerBox>
          {
            item1 ?(
              <FrontBoxItemTwo
                item={item1}
                paddingPaper={false}
                // @ts-ignore
                onClick={()=>onClickPost(item1.postLink)}
                itemName='item1'
                sectionId={sectionId}
                href={item1.postLink}
                onHover
                showDescription={false}
                showTitle={false}
              />
            ):null
          }
        </FrontContainerBox>
      </Grid>
      <Grid item md={6}>
        <Grid container>
          <Grid item xs={12}>
            <FrontContainerBox>
              {
                item2 ?(
                  <FrontBoxItemTwo
                    item={item2}
                    paddingPaper={false}
                    // @ts-ignore
                    onClick={()=>onClickPost(item2.postLink)}
                    itemName='item2'
                    sectionId={sectionId}
                    href={item2.postLink}
                    onHover
                    showCategory={false}
                    showAuthor={false}
                    showTitle={false}
                  />
                ):null
              }
            </FrontContainerBox>
          </Grid>
          <Grid item xs={12}>
            <FrontContainerBox>
              {
                item3 ?(
                  <FrontBoxItemTwo
                    item={item3}
                    paddingPaper={false}
                    // @ts-ignore
                    onClick={()=>onClickPost(item3.postLink)}
                    itemName='item3'
                    sectionId={sectionId}
                    href={item3.postLink}
                    onHover
                    showCategory={false}
                    showDescription={false}
                    showTitle={false}
                  />
                ):null
              }
            </FrontContainerBox>
          </Grid>
        </Grid>

      </Grid>

    </Grid>
  );
};