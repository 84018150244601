import React, { CSSProperties } from 'react';
import { useDrag } from 'react-dnd';
import { PostType } from '../types';
import { Box, Grid, makeStyles, Paper, Typography } from '@material-ui/core';
import StopIcon from '@material-ui/icons/Stop';
import moment from 'moment';

type BoxItemProps = {
  item: PostType,
  onChange?: (item: PostType, position: number) => void,
  onChangeGrid?: (item: PostType, name: string, position?: number) => void,
  width?: number,
  height?: number,
  horizontal?: boolean,
  paddingPaper?: boolean,
  showDescription?: boolean,
  showCategory?: boolean,
  showAuthor?: boolean,
  showTitle?: boolean,
  centerTitle?: boolean,
  imageLeft?: boolean,
  onHover?: Boolean,
};

const useStyles = makeStyles({
  category: {
    fontSize: 14,
    textTransform: 'uppercase',
    fontWeight: 800,
    lineHeight: 1.167,
    letterSpacing: '1px',
    position: 'relative',
    height: 30,
  },
  title: {
    fontFamily: 'Playfair Display',
    fontSize: 18,
    fontWeight: 500,
    lineHeight: 1.2,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 3,
    '-webkit-box-orient': 'vertical',
    textTransform: 'capitalize',
  },
  author: {
    color: '#f49ba0',
    fontSize: 10,
    textTransform: 'uppercase',
    fontWeight: 400,
    lineHeight: 1.5,
  },
  description: {
    color: '#999',
    fontSize: 13,
    lineHeight: 'normal',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 3,
    '-webkit-box-orient': 'vertical',
    textTransform: 'capitalize',
  },
  paper: {
    cursor: 'grab',
  },
});

export const BoxItemTwo: React.FC<BoxItemProps> = (
  {
    item,
    onChange,
    height = 'auto',
    width = '100%',
    horizontal = false,
    paddingPaper = true,
    showDescription = true,
    showAuthor = true,
    showCategory = true,
    imageLeft = true,
    centerTitle = false,
    onChangeGrid,
    showTitle = true,
    onHover = false,
  }) => {
  // @ts-ignore
  const [{ isDragging }, drag] = useDrag({
    item,
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();
      if (onChange && item && dropResult && dropResult.position) {
        onChange(item, dropResult.position);
      } else if (onChangeGrid && item && dropResult && dropResult.name) {
        onChangeGrid(item, dropResult.name, dropResult.position);
      }
    },
    collect: (monitor: { isDragging: () => void; }) => ({
      isDragging: monitor.isDragging(),
    }),

  });
  const classes = useStyles();
  const padding = paddingPaper ? 2 : 0;
  const isHover: CSSProperties = onHover ? {
    position: 'relative',
  } : {};

  const author = `| ${item.author}`;
  return (
    <Grid item md={12}>
      <Box pt={padding} pl={padding} pr={padding}>
        <Paper elevation={1} ref={drag} className={classes.paper} square>
          {
            !horizontal ? (
              <Box p={2}>
                <Box style={isHover}>
                  <img width={width} height={height} src={item.postImage} alt={item.title} />
                  {
                    onHover && (
                      <Box style={{ position: 'absolute', bottom: 0, padding: 8 }}>
                        <Box mb={1}>
                          <Typography
                            className={classes.author}>{`POR: ${item.author}  ${moment(item.date).format('DD MMMM YYYY')}`}</Typography>
                        </Box>
                        <Box mb={1} textAlign={centerTitle ? 'center' : 'inherit'}>
                          <Typography className={classes.title} variant='h2'>{item.title}</Typography>
                        </Box>
                      </Box>
                    )
                  }
                </Box>

                {
                  showTitle && (
                    <Box mb={1} textAlign={centerTitle ? 'center' : 'inherit'}>
                      <Typography className={classes.title} variant='h2'>{item.title}</Typography>
                    </Box>
                  )
                }
                {
                  showDescription && (
                    <Box mb={1}>
                      <Typography className={classes.description}>{item.description}</Typography>
                    </Box>
                  )
                }
                <Box mb={1}>
                  <Typography className={classes.category} variant='h3' color='primary'>
                    {`${item.category} ${showAuthor ? author : ''}`}
                  </Typography>
                </Box>
              </Box>
            ) : (
              <Box p={2}>
                <Grid container spacing={2}
                  direction='row'
                  justify='center'
                  alignItems='center'
                >
                  {
                    imageLeft ? (
                      <>
                        <Grid item md={6}>
                          <img width={width} height={height} src={item.postImage} alt={item.title} />
                        </Grid>
                        <Grid item md={6}>
                          <Box mb={1}>
                            <Typography className={classes.title} variant='h2'>{item.title}</Typography>
                          </Box>
                          {
                            showAuthor && (
                              <Box mb={1}>
                                <Typography
                                  className={classes.author}>{`POR: ${item.author} | ${moment(item.date).format('DD MMMM YYYY')}`}</Typography>
                              </Box>
                            )
                          }

                        </Grid>
                      </>
                    ) : (
                      <>
                        <Grid item md={6}>
                          <Box mb={1}>
                            <Typography className={classes.title} variant='h2'>{item.title}</Typography>
                          </Box>
                          <Box mb={1}>
                            <Typography className={classes.author}>{`POR: ${item.author} | ${item.date}`}</Typography>
                          </Box>
                        </Grid>
                        <Grid item md={6}>
                          <img width={width} height={height} src={item.postImage} alt={item.title} />
                        </Grid>
                      </>
                    )
                  }

                </Grid>
              </Box>
            )
          }
        </Paper>
      </Box>
    </Grid>
  );
};