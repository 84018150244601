import React from "react";
import {Box, Paper} from "@material-ui/core";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import './custom.css';
import {TemplatesSliderType} from "../../types";
import { styled } from '@material-ui/core/styles';
import {TooltipInfo} from "../tooltip/TooltipInfo";

const TemplateImage = styled("img")({
  '&:hover':{
    boxShadow:'0px 0px 5px 0px #e30612!important'
  },
  cursor:'pointer'
});

export const TemplatesSlider: React.FC<TemplatesSliderType> = ({
  selected= 'grid-one',
  onClick
}) => {

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };
  return (
    <Box mb={2}>
      <Paper elevation={1} square>
        <Box height={150}>
          <Carousel
            responsive={responsive}
            sliderClass='custom-slider'
          >
            <Box p={2} height={'100%'} >
              <TooltipInfo title={'Grid one'}>
                <TemplateImage
                  src='https://via.placeholder.com/170'
                  alt="item 1"
                  width='100%'
                  height='100%'
                  style={{boxShadow: selected === 'grid-one'? '0px 0px 5px 0px #e30612': 'none'}}
                  onClick={()=>{
                    if (onClick) {
                      onClick('grid-one');
                    }}}
                />
              </TooltipInfo>

            </Box>
            <Box p={2} height={'100%'}>
              <TooltipInfo title={'Grid two'}>
                <TemplateImage
                  src='https://via.placeholder.com/170'
                  alt="item 2"
                  width='100%'
                  height='100%'
                  style={{boxShadow: selected === 'grid-two'? '0px 0px 5px 0px #e30612': 'none'}}
                  onClick={()=>{
                    if (onClick) {
                      onClick('grid-two');
                    }}}
                />
              </TooltipInfo>
            </Box>
            <Box p={2} height={'100%'}>
              <TooltipInfo title={'Grid three'}>
                <TemplateImage
                  src='https://via.placeholder.com/170'
                  alt="item 3"
                  width='100%'
                  height='100%'
                  style={{boxShadow: selected === 'grid-three'? '0px 0px 5px 0px #e30612': 'none'}}
                  onClick={()=>{
                    if (onClick) {
                      onClick('grid-three');
                    }}}
                />
              </TooltipInfo>

            </Box>
            <Box p={2} height={'100%'}>
              <TooltipInfo title={'Grid four'}>
                <TemplateImage
                  src='https://via.placeholder.com/170'
                  alt="item 4"
                  width='100%'
                  height='100%'
                  style={{boxShadow: selected === 'grid-four'? '0px 0px 5px 0px #e30612': 'none'}}
                  onClick={()=>{
                    if (onClick) {
                      onClick('grid-four');
                    }}}
                />
              </TooltipInfo>
            </Box>
            <Box p={2} height={'100%'}>
              <TooltipInfo title={'Grid five'}>
                <TemplateImage
                  src='https://via.placeholder.com/170'
                  alt="item 5"
                  width='100%'
                  height='100%'
                  style={{boxShadow: selected === 'grid-five'? '0px 0px 5px 0px #e30612': 'none'}}
                  onClick={()=>{
                    if (onClick) {
                      onClick('grid-five');
                    }}}
                />
              </TooltipInfo>
            </Box>
            <Box p={2} height={'100%'}>
              <TooltipInfo title={'Grid five'}>
                <TemplateImage
                  src='https://via.placeholder.com/170'
                  alt="item 6"
                  width='100%'
                  height='100%'
                  style={{boxShadow: selected === 'grid-six'? '0px 0px 5px 0px #e30612': 'none'}}
                  onClick={()=>{
                    if (onClick) {
                      onClick('grid-six');
                    }}}
                />
              </TooltipInfo>
            </Box>
            <Box p={2} height={'100%'}>
              <TooltipInfo title={'Grid seven'}>
                <TemplateImage
                  src='https://via.placeholder.com/170'
                  alt="item 6"
                  width='100%'
                  height='100%'
                  style={{boxShadow: selected === 'grid-seven'? '0px 0px 5px 0px #e30612': 'none'}}
                  onClick={()=>{
                    if (onClick) {
                      onClick('grid-seven');
                    }}}
                />
              </TooltipInfo>
       
            </Box>
            <Box p={2} height={'100%'}>
              <TooltipInfo title={'Grid eight'}>
                <TemplateImage
                  src='https://via.placeholder.com/170'
                  alt="item 6"
                  width='100%'
                  height='100%'
                  style={{boxShadow: selected === 'grid-eight'? '0px 0px 5px 0px #e30612': 'none'}}
                  onClick={()=>{
                    if (onClick) {
                      onClick('grid-eight');
                    }}}
                />
              </TooltipInfo>

            </Box>
            <Box p={2} height={'100%'}>
              <TooltipInfo title={'Grid nine'}>
                <TemplateImage
                  src='https://via.placeholder.com/170'
                  alt="item 6"
                  width='100%'
                  height='100%'
                  style={{boxShadow: selected === 'grid-nine'? '0px 0px 5px 0px #e30612': 'none'}}
                  onClick={()=>{
                    if (onClick) {
                      onClick('grid-nine');
                    }}}
                />
              </TooltipInfo>

            </Box>
            <Box p={2} height={'100%'}>
              <TooltipInfo title={'Grid Ten'}>
                <TemplateImage
                  src='https://via.placeholder.com/170'
                  alt="item 6"
                  width='100%'
                  height='100%'
                  style={{boxShadow: selected === 'grid-ten'? '0px 0px 5px 0px #e30612': 'none'}}
                  onClick={()=>{
                    if (onClick) {
                      onClick('grid-ten');
                    }}}
                />
              </TooltipInfo>

            </Box>
            <Box p={2} height={'100%'}>
              <TooltipInfo title={'Grid Eleven'}>
                <TemplateImage
                  src='https://via.placeholder.com/170'
                  alt="item 6"
                  width='100%'
                  height='100%'
                  style={{boxShadow: selected === 'grid-eleven'? '0px 0px 5px 0px #e30612': 'none'}}
                  onClick={()=>{
                    if (onClick) {
                      onClick('grid-eleven');
                    }}}
                />
              </TooltipInfo>
            </Box>
            <Box p={2} height={'100%'}>
              <TooltipInfo title={'Grid Twelve'}>
                <TemplateImage
                  src='https://via.placeholder.com/170'
                  alt="item 6"
                  width='100%'
                  height='100%'
                  style={{boxShadow: selected === 'grid-twelve'? '0px 0px 5px 0px #e30612': 'none'}}
                  onClick={()=>{
                    if (onClick) {
                      onClick('grid-twelve');
                    }}}
                />
              </TooltipInfo>
            </Box>
          </Carousel>
        </Box>
      </Paper>
    </Box>
  );
};