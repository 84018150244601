import moment from "moment";

export const SETTINGS = {
  homeUrl: 'https://elefanteblanco.digitalroom.tech',
  userId: 1,
  isAdmin: true,
  sectionId: 1,
  date: moment().format(moment().format(moment.HTML5_FMT.DATETIME_LOCAL))
};

export const CREATE_SECTION_TEXT = '¿Estas seguro que deseas crear esta sección?';

export const UPDATE_SECTION_TEXT = '¿Estas seguro que deseas actualizar esta sección?';

export const CANCEL_DIALOG_TEXT = 'Cancelar';

export const ON_YES_DIALOG_TEXT = 'Crear';

export const ON_YES_DELETE_DIALOG_TEXT = 'Eliminar';

export const ON_YES_UPDATE_DIALOG_TEXT = 'Actualizar';

export const CREATE_TITLE_TEXT = 'Crear sección';

export const ON_YES_PROGRAM_DIALOG_TEXT = 'Guardar';

export const PROGRAM_TITLE_TEXT = 'Horario de programacion';

export const SECTIONS = [{sectionId: 1 }, {sectionId: 2}];