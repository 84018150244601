import React, {CSSProperties} from 'react';
import {useDrag} from "react-dnd";
import {PostType} from "../../../../types";
import {Box, Grid, Link, makeStyles, Paper, Typography, useMediaQuery} from "@material-ui/core";
import StopIcon from '@material-ui/icons/Stop';
import moment from "moment";
import {useTheme} from "@material-ui/core/styles";
import 'moment/locale/es';
moment().locale('es');

type BoxItemProps = {
    item: PostType,
    width?: number,
    height?: number,
    horizontal?: boolean,
    paddingPaper?: boolean,
    showDescription?: boolean,
    showCategory?: boolean,
    showAuthor?: boolean,
    showTitle?: boolean,
    centerTitle?: boolean,
    imageLeft?: boolean,
    onHover?: boolean,
    onClick?: () => void,
    sectionId?: string,
    itemName?: string,
    href?: string,
};

const useStyles = makeStyles({
  category: {
    fontSize: 14,
    textTransform: 'uppercase',
    fontWeight: 800,
    lineHeight: 1.167,
    letterSpacing: '1px',
    position: 'relative',
    height: 30,
  },
  title: {
    fontFamily: 'Playfair Display',
    fontSize: 18,
    fontWeight: 500,
    lineHeight: 1.2,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 3,
    '-webkit-box-orient': 'vertical',
    textTransform: 'capitalize',
  },
  author: {
    color: '#f49ba0',
    fontSize: 10,
    textTransform: 'uppercase',
    fontWeight: 400,
    lineHeight: 1.5,
  },
  description: {
    color: '#999',
    fontSize: 13,
    lineHeight: 'normal',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 3,
    '-webkit-box-orient': 'vertical',
    textTransform: 'capitalize',
  },
});

export const FrontBoxItemTwo: React.FC<BoxItemProps> = ({
  item,
  height = 'auto',
  width = '100%',
  horizontal = false,
  paddingPaper = true,
  showDescription = true,
  showAuthor = true,
  showCategory = true,
  imageLeft = true,
  centerTitle = false,
  onClick,
  sectionId = '',
  itemName = '',
  href = '',
  onHover=false,
  showTitle=true,
}) => {
  // @ts-ignore

  const classes = useStyles();
  const padding = paddingPaper ? 2 : 0;

  const isHover: CSSProperties = onHover ? {
    position:'relative'
  }:{};

  const author = `| ${item.author}`;
  return (
    <Grid item md={12}>
      <Link href={href}>
        <Box pt={padding} pl={padding} pr={padding}>
          <Paper elevation={0} square>
            {
              !horizontal ? (
                <Box p={2}>
                  <Box style={isHover}>
                    <img width={width} height={height} src={item.postImage} alt={item.title}/>
                    {
                      onHover && (
                        <Box style={{position:'absolute', bottom:0 , padding:8}}>
                          <Box mb={1}>
                            <Link href={item.authorLink}>
                              <Typography className={classes.author}>{`POR: ${item.author}  ${moment(item.date).format('DD MMMM YYYY')}`}</Typography>
                            </Link>
                          </Box>
                          <Box mb={1} textAlign={centerTitle ? 'center': 'inherit'}>
                            <Typography className={classes.title} variant='h2'>{item.title}</Typography>
                          </Box>
                        </Box>
                      )
                    }
                  </Box>

                  {
                    showTitle && (
                      <Box mb={1} textAlign={centerTitle ? 'center' : 'inherit'}
                        className={`${itemName}-${sectionId}`}>
                        <Typography className={classes.title} variant='h2'>{item.title}</Typography>
                      </Box>
                    )
                  }
                  {
                    showDescription && (
                      <Box mb={1}>
                        <Typography
                          className={classes.description}>{item.description}</Typography>
                      </Box>
                    )
                  }

                  <Box mb={1}>
                    <Typography className={classes.category} variant='h3' color='primary'>
                      {`${item.category} ${showAuthor ? author : ''}`}
                    </Typography>
                  </Box>
                </Box>
              ) : (
                <Box p={2}>
                  <Grid container spacing={2}
                    direction='row'
                    justify='center'
                    alignItems='center'
                  >
                    {
                      imageLeft ? (
                        <>
                          <Grid item md={6} xs={6}>
                            <img width={width} height={height} src={item.postImage}
                              alt={item.title}/>
                          </Grid>
                          <Grid item md={6} xs={6}>
                            {
                              showCategory && (
                                <Box mb={1}>
                                  <Typography className={classes.category} variant='h3' color='primary'>
                                    {/*<StopIcon color='primary' style={{position:'absolute', top:0, left:0, height:32, width:32}}/>*/}
                                    {item.category}
                                  </Typography>
                                </Box>
                              )
                            }
                            {
                              showTitle && (
                                <Box mb={1} className={`${itemName}-${sectionId}`}>
                                  <Typography className={classes.title}
                                    variant='h2'>{item.title}</Typography>
                                </Box>  
                              )
                            }
                       
                            {
                              showAuthor && (
                                <Box mb={1}>
                                  <Link href={item.authorLink}>
                                    <Typography
                                      className={classes.author}>{`POR: ${item.author} | ${moment(item.date).format('DD MMMM YYYY')}`}</Typography>
                                  </Link>
                                </Box>
                              )
                            }
                            {
                              showDescription && (
                                <Box mb={1}>
                                  <Typography
                                    className={classes.description}>{item.description}</Typography>
                                </Box>
                              )
                            }
                          </Grid>
                        </>
                      ) : (
                        <>
                          <Grid item md={6} xs={6}>
                            {
                              showCategory && (
                                <Box mb={1}>
                                  <Typography className={classes.category} variant='h3' color='primary'>
                                    {/*<StopIcon color='primary' style={{position:'absolute', top:0, left:0, height:32, width:32}}/>*/}
                                    {item.category}
                                  </Typography>
                                </Box>
                              )
                            }
                            <Box mb={1} className={`${itemName}-${sectionId}`}>
                              <Typography className={classes.title}
                                variant='h2'>{item.title}</Typography>
                            </Box>
                            <Box mb={1}>
                              <Link href={item.authorLink}>

                                <Typography
                                  className={classes.author}>{`POR: ${item.author} | ${item.date}`}</Typography>
                              </Link>
                            </Box>
                            {
                              showDescription && (
                                <Box mb={1}>
                                  <Typography
                                    className={classes.description}>{item.description}</Typography>
                                </Box>
                              )
                            }
                          </Grid>
                          <Grid item md={6} xs={6}>
                            <img width={width} height={height} src={item.postImage}
                              alt={item.title}/>
                          </Grid>
                        </>
                      )
                    }

                  </Grid>
                </Box>
              )
            }
          </Paper>
        </Box>
      </Link>
    </Grid>
  );
};